import React, { Component } from 'react';
import { Layout, Row, Col, Form, Input, InputNumber, Button, Alert, Icon } from 'antd';
import axios from 'axios';
import MaskedInput from 'antd-mask-input'
import ReCAPTCHA from 'react-google-recaptcha';
import { init, send } from 'emailjs-com';

init("user_gieh6eejufsUx4RO3ZM21");

const { Content } = Layout;
const { Item } = Form;

class Payment extends Component {
  state = {
    error: '',
    orders: 1,
    loading: false,
    captcha: false
  }

  handleSubmit = e => {
    const { form: { validateFields } } = this.props;
    e.preventDefault();
    validateFields(async (err, values) => {
      values.expiry = values.expiry.replace(/\//g, '');
      values.ccnumber = values.ccnumber.replace(/\s/g, '');
      values.country = 'United States';
      if (!err) {
        let order = '';
        for (let value in values) {
          if(value.indexOf('order-') > -1) {
            order = !order ? `${values[value]}` : `${order}, ${values[value]}`;
            delete values[value];
          }
        }
        values.order = order;
        this.setState({ loading: true });
        try {
          await axios.post('https://usaprintingtrade.herokuapp.com/api/ChargeCreditCards/process', { payload: values });
          const templateParams = {
            userName: `${values.firstName} ${values.lastName}`,
            userEmail: values.email,
            companyName: values.company,
            address: values.address,
            amount: `${parseFloat(values.amount).toFixed(2)}`,
            order: values.order,
          };
          const emailRes = await send('service_9gcz4jm', 'template_ctixfh7', templateParams);
          console.log(emailRes);
          this.setState({ loading: false, error: '' });
          this.props.history.push('/thankyou');
        } catch(err) {
          this.setState({ loading: false, error: err.response.data.error.message });
        };
      }
    });
  }

  addOrder = () => {
    this.setState(prevState => ({
      orders: prevState.orders + 1
    }));
  }

  removeOrder = () => {
    this.setState(prevState => ({
      orders: prevState.orders - 1
    }));
  }

  handleCaptcha = () => {
    this.setState({ captcha: true });
  }

  render() {
    const { form: { getFieldDecorator } } = this.props;
    const { loading, captcha, orders } = this.state;
    let orderFields = [];
    for(let i = 0; i < orders; i ++) {
      orderFields.push(
        <Col sm={8} xs={24}>
          <label>Order Number</label>
          <Item className='order-input'>
            { getFieldDecorator(`order-${i}`, {
              rules: [{ required: true, message: 'Please enter Order Number!' }]
            })(
              <Input />
            )}
            { i > 0 && <Icon className='remove-icon' type='close' onClick={this.removeOrder} /> }
          </Item>
        </Col>
      );
    }
    return (
      <Content>
        <h2>USA PRINTING TRADE - Payment Center</h2>
        <p>Please use this secure payment form to send payments to us.</p>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col lg={6} md={8} sm={12} xs={24}>
              <label>First Name</label>
              <Item>
                { getFieldDecorator('firstName', {
                  rules: [{ required: true, message: 'Please enter first name!' }]
                })(
                  <Input />
                )}
              </Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <label>Last Name</label>
              <Item>
                { getFieldDecorator('lastName', {
                  rules: [{ required: true, message: 'Please enter last name!' }]
                })(
                  <Input />
                )}
              </Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <label>Email</label>
              <Item>
                { getFieldDecorator('email', {
                  rules: [
                    { required: true, message: 'Please enter email!' },
                    { type: 'email', message: 'Invalid Email!' }
                  ]
                })(
                  <Input />
                )}
              </Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <label>Company Name</label>
              <Item>
                { getFieldDecorator('company', {
                  rules: [{ required: true, message: 'Please enter company name!' }]
                })(
                  <Input />
                )}
              </Item>
            </Col>
          </Row>

          <div className='flex-row align-items-center my10'>
            <h2 className='m0'>Cards Accepted</h2>
            <div className='flex-row px8'>
              <img src='/images/visa.png' alt='visa' />
              <img src='/images/mc.png' alt='master card' />
              <img src='/images/dis.png' alt='discover' />
              <img src='/images/ae.png' alt='ae' />
            </div>
          </div>
            
          <Row gutter={16}>
            <Col lg={6} md={8} sm={24}>
              <label>Credit Card Number</label>
              <Item>
                { getFieldDecorator('ccnumber', {
                  rules: [{ required: true, message: 'Please enter credit card number!' }]
                })(
                  <MaskedInput mask='1111 1111 1111 1111' placeholder='1234 1234 1234 1234' />
                )}
              </Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <label>Expiry</label>
              <Item>
                { getFieldDecorator('expiry', {
                  rules: [{ required: true, message: 'Please enter credit card expiry!' }]
                })(
                  <MaskedInput mask='11/11' placeholder='MM/YY' />
                )}
              </Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <label>CVC</label>
              <Item>
                { getFieldDecorator('cvc', {
                  rules: [
                    { required: true, message: 'Please enter security code' },
                    { min: 3, max: 4, message: 'Invalid security Code' }
                  ]
                })(
                  <Input type='number' placeholder='123' />
                )}
              </Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <label>Amount</label>
              <Item>
                { getFieldDecorator('amount', {
                  rules: [{ required: true, message: 'Please enter amount!' }]
                })(
                  <InputNumber className='w100' />
                )}
              </Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24}>
              <label>Billing Address</label>
              <Item>
                { getFieldDecorator('address', {
                  rules: [{ required: true, message: 'Please enter address!' }]
                })(
                  <Input />
                )}
              </Item>
            </Col>
            <Col sm={8} xs={24}>
              <label>City</label>
              <Item>
                { getFieldDecorator('city', {
                  rules: [{ required: true, message: 'Please enter city name!' }]
                })(
                  <Input />
                )}
              </Item>
            </Col>
            <Col sm={8} xs={24}>
              <label>State</label>
              <Item>
                { getFieldDecorator('state', {
                  rules: [{ required: true, message: 'Please enter state!' }]
                })(
                  <Input />
                )}
              </Item>
            </Col>
            
            <Col sm={8} xs={24}>
              <label>Zip Code</label>
              <Item>
                { getFieldDecorator('zip', {
                  rules: [{ required: true, message: 'Please enter zip code!' }]
                })(
                  <InputNumber className='w100' />
                )}
              </Item>
            </Col>
            {orderFields}
          </Row>
          <Button
            className='my10'
            type='primary'
            size='large'
            onClick={this.addOrder}
          >
            Add Another Order
          </Button>
          { this.state.error &&
            <Alert
              style={{ marginBottom: 10 }}
              showIcon
              type='error'
              message={this.state.error}
            />
          }
          <ReCAPTCHA
            sitekey="6LfWsu8UAAAAAMrWi2ygTIJsJDLml9UZnn_JHBWo"
            onChange={this.handleCaptcha}
          />
          <Button
            className='my10'
            htmlType='submit'
            type='primary'
            size='large'
            loading={loading}
            disabled={loading || !captcha}
          >
            Submit
          </Button>
        </Form>
      </Content>
    );
  }
};

const PaymentCenter = Form.create()(Payment);
export { PaymentCenter };
