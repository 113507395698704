import React from 'react';
import { Result } from 'antd';

const Thankyou = () => {
  return (
    <Result
      status='success'
      title='Thank You!'
      subTitle='Your payment was successful!'
    />
  );
};

export { Thankyou };
