import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { PaymentCenter } from './PaymentCenter';
import { Thankyou } from './Thankyou';

import './App.css';

class App extends Component {
  render() {
    return (
      <Layout className='p15' style={{ minHeight: '100vh', background: 'white' }}>
        <Switch>
          <Route
            exact
            path='/'
            render={(prevProps) =>
              <PaymentCenter
                {...prevProps}
              />
            }
          />
          <Route
            exact
            path='/thankyou'
            render={(prevProps) =>
              <Thankyou
                {...prevProps}
              />
            }
          />
        </Switch>
      </Layout>
    );
  }
}

export default App;
